<template>
  <div class="CustomNavContainer h-100">
    <div class="ArrowContainer">
      <button class="btn" v-if="isCustomNavOpen" @click="toggleCustomNav">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.41 8.58008L12 13.1701L16.59 8.58008L18 10.0001L12 16.0001L6 10.0001L7.41 8.58008Z"
            fill="#fff"
          />
        </svg>
      </button>

      <button v-else class="btn" @click="toggleCustomNav">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.59 15.4199L12 10.8299L7.41 15.4199L6 13.9999L12 7.99992L18 13.9999L16.59 15.4199Z"
            fill="#fff"
          />
        </svg>
      </button>
    </div>

    <!-- Main Content -->
    <div class="d-flex flex-column justify-content-between h-100">
      <!-- All Pages Section start -->
      <Transition name="quizContainerFade">
        <div class="h-100" v-if="isCustomNavOpen">
          <div class="row AllQuiz py-2">
            <div class="col-12 d-flex align-items-center">
              <div
                class="w-100 d-flex align-items-center pb-4 pt-2"
                style="overflow-x: scroll"
                ref="AllPagesContainerRef"
                @wheel="scrollHorizontally"
                :style="`transform: translateX(-${scrollOffset}px)`"
              >
                <draggable
                  v-model="pages"
                  v-bind="dragOptions"
                  @start="dragging = true"
                  @end="dragging = false"
                  style="cursor: move"
                  class="d-flex"
                >
                  <div
                    v-for="(page, index) in pages"
                    :key="page.id"
                    class=""
                    v-show="page.value !== 'resultPage'"
                  >
                    <!-- @mouseover="showPageName(page.name)"
                  @mouseleave="hidePageName" -->
                    <div
                      class="quizContainer d-flex justify-content-center align-items-center"
                      :class="page.id == CurrentPage.id ? 'selectedPage' : null"
                      @click.stop="changePageSelected(page)"
                      v-b-tooltip.hover.bottom.window="{ variant: 'light' }"
                      :title="shrinkName(page.name)"
                    >
                      <!-- page menu start -->
                      <div class="pageMenuContainer">
                        <PageOptionsModal
                          :block="page"
                          :index="page.id"
                          :pageIndex="index"
                          @duplicatePage="initateDuplicatePage($event)"
                          :duplicateSpinner="duplicateSpinner"
                          :deleteSpinner="deleteSpinner"
                          :isCloseModal="isClosePageOptionModal"
                          @initateDeletePage="DeletePage($event)"
                        />
                      </div>
                      <!-- page menu End -->
                      <GetStartedPagePreviewComp
                        v-if="checkIsGetStarted(page.value)"
                        :height="100"
                        :width="140"
                        :pageDesign="page.pageDesign"
                        :order="page.order"
                      />
                      <QuestionLeadsStripePage
                        v-if="checkIsDataPage(page.value)"
                        :height="100"
                        :width="140"
                        :pageDesign="page.pageDesign"
                        :order="page.order"
                        :CurrentPageValue="page.value"
                      />
                    </div>
                  </div>
                </draggable>
              </div>
              <!-- Add Quiz -->
              <div class="d-flex align-items-center h-100 pb-4 pt-2 px-3">
                <div
                  v-for="(page, index) in pages"
                  :key="page.id"
                  class=" "
                  v-show="page.value == 'resultPage'"
                >
                  <!-- @mouseover="showPageName(page.name)"
                  @mouseleave="hidePageName" -->
                  <div
                    class="quizContainer d-flex justify-content-center align-items-center"
                    :class="page.id == CurrentPage.id ? 'selectedPage' : null"
                    @click.stop="changePageSelected(page)"
                    v-b-tooltip.hover.bottom.window="{ variant: 'light' }"
                    :title="shrinkName(page.name)"
                  >
                    <!-- page menu start -->
                    <div class="pageMenuContainer">
                      <PageOptionsModal
                        class="threeDotsContainer"
                        :block="page"
                        :index="page.id"
                        :pageIndex="index"
                      />
                    </div>
                    <!-- page menu End -->
                    <ResultPreviewPage
                      v-if="page.value == 'resultPage'"
                      :height="100"
                      :width="140"
                      :pageDesign="page.pageDesign"
                      :order="page.order"
                      :CurrentPage="page"
                    />
                  </div>
                </div>

                <div
                  class="quizContainer AddPageContainer d-flex justify-content-center align-items-center"
                  style="color: #0397cc; font-size: 14px"
                  @click="addNewPage"
                >
                  <BIconPlus font-scale="2" />
                  <span>Add Page</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <!-- All Pages Section End -->

      <!-- nav Elements Start -->
      <div class="" v-if="getTotalPages.length > 0">
        <nav
          class="navbarCustom d-lg-flex d-md-flex align-items-center justify-content-between py-2"
        >
          <div class="navbar__middle">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div
                  @click="toggleView('desktop')"
                  v-b-tooltip.hover="{ variant: 'light' }"
                  title="Edit quiz for desktop view"
                  class="navbar__icon-container d-flex justify-content-center align-items-center rounded-lg large mx-1"
                  :class="{ active: getEditorPageView === 'desktop' }"
                >
                  <svg
                    width="24"
                    height="22"
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.9906 13.2895V2.05614C23.9906 0.93692 23.0606 0 21.9293 0H2.07075C0.939188 0 0.00937498 0.93692 0.00937498 2.05614V13.2895C0.00937498 13.3225 0 13.3561 0 13.391C0 13.4255 0.00937498 13.4592 0.00937498 13.4922V15.4586C0.0144375 16.5817 0.935063 17.4903 2.07075 17.4933H8.94244V19.0637C8.94637 19.5327 8.736 19.978 8.37019 20.276L7.71656 20.814H5.78156C5.45044 20.814 5.18194 21.0796 5.18194 21.4071C5.18194 21.7346 5.45044 22 5.78156 22H18.4779C18.8092 22 19.0776 21.7346 19.0776 21.4071C19.0776 21.0796 18.8092 20.814 18.4779 20.814H16.2834L15.6298 20.276C15.264 19.978 15.0536 19.5327 15.0576 19.0637V17.4933H21.9293C23.0649 17.4903 23.9852 16.5817 23.9906 15.4586V13.4922C23.9906 13.4592 24 13.4255 24 13.391C24 13.3561 23.9906 13.3225 23.9906 13.2895ZM2.07075 1.18599H21.9293C22.3995 1.18599 22.7916 1.59102 22.7916 2.05614V12.8087H1.20844V2.05614C1.20844 1.59102 1.6005 1.18599 2.07075 1.18599ZM14.472 20.814H9.52819C9.93206 20.3396 10.1415 19.7067 10.1415 19.0637V17.4933H13.8585V19.0637C13.8585 19.7067 14.0679 20.3396 14.472 20.814ZM21.9293 16.3073H2.07075C1.59694 16.3052 1.21294 15.9269 1.20844 15.4586V13.9946H22.7916V15.4586C22.7871 15.9269 22.4031 16.3052 21.9293 16.3073Z"
                      fill="#4D4950"
                    />
                  </svg>
                </div>
                <div
                  v-b-tooltip.hover="{ variant: 'light' }"
                  title="Edit quiz for mobile view"
                  @click="toggleView('mobile')"
                  class="navbar__icon-container d-flex justify-content-center align-items-center rounded-lg large"
                  :class="{ active: getEditorPageView === 'mobile' }"
                >
                  <svg
                    width="16"
                    height="24"
                    viewBox="0 0 16 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3125 0.9H13.3125L3.12284 0.9L3.12279 0.9C1.8834 0.90064 0.900411 2.04258 0.9 3.42288V3.42291L0.9 20.5771L0.9 20.5771C0.900411 21.9574 1.8834 23.0994 3.12279 23.1H3.12284H13.3125H13.3125C14.5519 23.0994 15.5347 21.9574 15.5353 20.5771V20.5771V3.42291V3.42287C15.5347 2.04259 14.5519 0.90064 13.3125 0.9ZM14.4862 20.5768C14.4843 21.3364 13.9477 21.9288 13.3121 21.9308H3.12316C2.48758 21.9288 1.95108 21.3365 1.94913 20.577V3.42304C1.95108 2.66361 2.4875 2.07131 3.12302 2.06916H13.3123C13.9478 2.07131 14.4843 2.66367 14.4862 3.42316V20.5768Z"
                      fill="#4D4950"
                      stroke="#B3AFB6"
                      stroke-width="0.2"
                    />
                    <path
                      d="M6.44675 19.6689H9.84329C10.145 19.6689 10.3679 19.3943 10.3679 19.0843C10.3679 18.7743 10.145 18.4997 9.84329 18.4997H6.44675C6.14492 18.4997 5.92219 18.7744 5.92219 19.0843C5.92219 19.3943 6.14492 19.6689 6.44675 19.6689Z"
                      fill="#4D4950"
                      stroke="#B3AFB6"
                      stroke-width="0.2"
                    />
                  </svg>
                </div>
              </div>

              <!-- PRev NExt start -->
              <div
                class="navbar__icon-container d-flex justify-content-center align-items-center rounded-circle mx-2"
                style="cursor: pointer; background: #b3afb6"
                @click="prevPage"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00002 0.333374L8.17502 1.50837L3.52502 6.16671H13.6667V7.83337H3.52502L8.17502 12.4917L7.00002 13.6667L0.333355 7.00004L7.00002 0.333374Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                class="navbar__icon-container d-flex justify-content-center align-items-center rounded-circle"
                style="cursor: pointer; background: #b3afb6"
                @click="nextPage"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.99998 0.333374L5.82498 1.50837L10.475 6.16671H0.333313V7.83337H10.475L5.82498 12.4917L6.99998 13.6667L13.6666 7.00004L6.99998 0.333374Z"
                    fill="white"
                  />
                </svg>
              </div>
              <!-- PRev NExt End -->
            </div>
          </div>
          <div
            class="navbar__right d-flex align-items-center justify-content-between flex-wrap"
          >
            <!-- Zoom Container start -->
            <div
              class="zoomSliderContainer d-flex align-items-center justify-content-end"
            >
              <div class="d-flex">
                <p class="m-0 p-0 pageNumber editorStyleTitle pt-2">Zoom</p>
                <div class="space-vertical pl-3"></div>
              </div>

              <div class="d-flex align-items-center pl-3 zoomSliderWrapper">
                <input
                  type="range"
                  class="zoomSlider"
                  min="1"
                  max="200"
                  step="1"
                  v-model="zoomValue"
                  id="zoomSlider"
                />
                <label for="zoomSlider" class="m-0 p-0 px-2 pl-4 zoomValue"
                  >{{ pageZoomValue }}%</label
                >
              </div>
            </div>

            <!-- Zoom Container End -->

            <!-- Preview Button Start -->
            <button
              class="outline-none border-0 px-3 py-1 ml-1 mr-3"
              style="
                cusrsor: pointer;
                height: 43px;
                border-radius: 6px;
                background: #b3afb6;
              "
              @click="navigateToPreview"
            >
              <svg
                class="mr-2"
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                  fill="white"
                />
              </svg>
              <span style="color: #ffffff">Preview</span>
            </button>

            <!-- Save Button Start -->
            <button
              @click="saveData"
              class="outline-none SaveNavbar outline-none border-0 px-3 py-1"
              style="cursor: pointer"
              id="saveDataButton"
              :class="
                !getIsAnyPageEdited || isEditorQuizSaveLoading
                  ? 'disabledButton'
                  : null
              "
              :disabled="!getIsAnyPageEdited || isEditorQuizSaveLoading"
            >
              <span v-if="!isEditorQuizSaveLoading">Save</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>

            <!-- FullScreen Mode Btn for Shopify -->

            <button
              v-b-tooltip.hover
              title="View App in fullscreen"
              class="btn ml-3"
              v-if="ShopifyEmbedCondition"
              @click="openFullScreenApp"
            >
              <BIconArrowsFullscreen />
            </button>
          </div>
        </nav>

        <!-- ChooseTemplateModal Start -->
        <ChooseTemplateModal
          ref="ChooseTemplateNavRef"
          @UpdateQuiz="updateQuizNav"
        />
        <!-- ChooseTemplateModal End -->
      </div>
      <!-- nav Elements End -->

      <!-- Quiz Name Content Start -->
      <Transition name="quizNameFade">
        <div class="QuizNameContainer" v-if="showName && currPageName">
          <p class="m-0 p-0 text-white">{{ currPageName }}</p>
        </div>
      </Transition>
      <!-- Quiz Name Content End -->
    </div>

    <div
      class="modal fade"
      id="DeleteQuestionModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="DeleteQuestionModalRef"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content DeleteQuizModalContent">
          <div class="container">
            <div>
              <div class="container py-3 h-100">
                <div class="">
                  <div class="row mb-5">
                    <div
                      class="w-100 d-flex flex-column justify-content-between align-items-center"
                    >
                      <p class="Title">Are you sure ?</p>
                      <p class="TitleSub text-center">
                        Page and data will be permanently deleted.
                      </p>
                      <div
                        class="d-flex align-items-center w-100 justify-content-center"
                      >
                        <button
                          @click="
                            removePage(
                              initiateDeletPage.index,
                              initiateDeletPage.page
                            )
                          "
                          class="CreateBtn mr-5 outline-none text-white outline-none border-0 px-4 py-2"
                          style="background: #ea1601cc; border-radius: 6px"
                        >
                          <span v-if="!deleteSpinner">Delete</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </button>
                        <button
                          @click="CloseDeleteQustionModal"
                          class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                          style="background: #4d1b7eaa; border-radius: 6px"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Open Add Question Modal -->

    <div
      class="modal fade"
      id="AddQuestionModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="AddQuestionModalRef"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content DeleteQuizModalContent">
          <div class="container">
            <div>
              <div class="container py-3 h-100">
                <div class="">
                  <div class="row mb-5">
                    <div
                      class="w-100 d-flex flex-column justify-content-between align-items-center"
                    >
                      <div
                        class="d-flex w-100 justify-content-between align-items-start"
                        style="padding: 2px 20px 20px"
                      >
                        <p
                          class=""
                          style="
                            font-family: Poppins;
                            font-size: 23px;
                            font-weight: 400;
                            line-height: 32.2px;
                            text-align: left;
                            color: #000;
                          "
                        >
                          Add new Page
                        </p>
                        <i
                          class="fa fa-times"
                          @click="closeNewPageModal"
                          style="cursor: pointer"
                        ></i>
                      </div>

                      <div class="row w-100">
                        <div class="col-4">
                          <div class="pageContainerMain">
                            <p class="pageTitle">Lead Page To Collect:</p>

                            <div
                              class="d-flex align-items-center mb-2"
                              v-for="feature in leadPageFeatures"
                              :key="feature.id"
                            >
                              <span class="mr-2" v-html="feature.svg"></span>
                              <span class="title">{{ feature.title }}</span>
                            </div>

                            <Button
                              class="w-100 mt-2 d-flex justify-content-center align-items-center"
                              @click="addPage(addPageValues1[3])"
                            >
                              <b-spinner
                                v-if="
                                  addPageLoader &&
                                  selectedNewPage == addPageValues1[3]
                                "
                                small
                                class="mr-2"
                                label="Small Spinner"
                              ></b-spinner>
                              Create Lead Page</Button
                            >
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="pageContainerMain">
                            <p class="pageTitle">Question Page:</p>

                            <div
                              class="d-flex align-items-center mb-2"
                              v-for="types in questionPagesTypes"
                              :key="types.id"
                            >
                              <span class="mr-2" v-html="types.svg"></span>
                              <span class="title">{{ types.title }}</span>
                            </div>
                            <Button
                              class="w-100 mt-2 d-flex justify-content-center align-items-center"
                              @click="addPage(addPageValues1[1])"
                            >
                              <b-spinner
                                v-if="
                                  addPageLoader &&
                                  selectedNewPage == addPageValues1[1]
                                "
                                small
                                class="mr-2"
                                label="Small Spinner"
                              ></b-spinner>
                              Create Question Page</Button
                            >
                          </div>
                        </div>

                        <div class="col-4">
                          <div
                            class="pageContainerMain"
                            style="background: transparent"
                          >
                            <p class="pageTitle">More Pages:</p>
                            <div
                              class="d-flex align-items-center mb-3 cursor-pointer"
                              @click="addPage(addPageValues1[0])"
                            >
                              <span class="mr-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    fill="#EAEBED"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    stroke="#DADADC"
                                  />
                                  <path
                                    d="M9.33331 7.42667V16.76L16.6666 12.0933L9.33331 7.42667Z"
                                    fill="#292929"
                                  />
                                </svg>
                              </span>
                              <span class="title">Get Started</span>
                            </div>
                            <div
                              class="d-flex align-items-center mb-2 cursor-pointer"
                              v-if="isDisablePaymentPage(PaymentPageData.value)"
                              v-b-tooltip.top
                              title="Please integrate Stripe or PayPal to use Payment Page"
                              style="opacity: 0.5"
                            >
                              <span class="mr-2"
                                ><svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    fill="#EAEBED"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    stroke="#DADADC"
                                  />
                                  <path
                                    d="M8.66669 14H10C10 14.72 10.9134 15.3333 12 15.3333C13.0867 15.3333 14 14.72 14 14C14 13.2667 13.3067 13 11.84 12.6467C10.4267 12.2933 8.66669 11.8533 8.66669 10C8.66669 8.80667 9.64669 7.79333 11 7.45333V6H13V7.45333C14.3534 7.79333 15.3334 8.80667 15.3334 10H14C14 9.28 13.0867 8.66667 12 8.66667C10.9134 8.66667 10 9.28 10 10C10 10.7333 10.6934 11 12.16 11.3533C13.5734 11.7067 15.3334 12.1467 15.3334 14C15.3334 15.1933 14.3534 16.2067 13 16.5467V18H11V16.5467C9.64669 16.2067 8.66669 15.1933 8.66669 14Z"
                                    fill="#292929"
                                  /></svg
                              ></span>
                              <span class="title"> Payment</span>
                            </div>
                            <div
                              class="d-flex align-items-center mb-2 cursor-pointer"
                              v-else
                              @click="addPage(PaymentPageData)"
                            >
                              <span class="mr-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    fill="#EAEBED"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="5.5"
                                    stroke="#DADADC"
                                  />
                                  <path
                                    d="M8.66669 14H10C10 14.72 10.9134 15.3333 12 15.3333C13.0867 15.3333 14 14.72 14 14C14 13.2667 13.3067 13 11.84 12.6467C10.4267 12.2933 8.66669 11.8533 8.66669 10C8.66669 8.80667 9.64669 7.79333 11 7.45333V6H13V7.45333C14.3534 7.79333 15.3334 8.80667 15.3334 10H14C14 9.28 13.0867 8.66667 12 8.66667C10.9134 8.66667 10 9.28 10 10C10 10.7333 10.6934 11 12.16 11.3533C13.5734 11.7067 15.3334 12.1467 15.3334 14C15.3334 15.1933 14.3534 16.2067 13 16.5467V18H11V16.5467C9.64669 16.2067 8.66669 15.1933 8.66669 14Z"
                                    fill="#292929"
                                  />
                                </svg>
                              </span>
                              <span class="title"> Payment</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import draggable from "vuedraggable";
import InitialPageValues from "../staticPages/IntialPageValues.js";
import ChooseTemplateModal from "../../Dashboard/ChooseTemplateModal";
import Button from "../../Dashboard/Layout/Button.vue";
import { BIconArrowsFullscreen, BIconPlus } from "bootstrap-vue";
export default {
  components: {
    draggable,
    Button,
    ChooseTemplateModal,
    BIconArrowsFullscreen,
    BIconPlus,
    PageOptionsModal: () =>
      import(
        /* webpackChunkName: "PageOptionsModal" */ "./CustomQuizNavComp/PageOptionsModal.vue"
      ),
    GetStartedPagePreviewComp: () =>
      import(
        /* webpackChunkName: "GetStartedPagePreviewComp" */ "../../NavbarPagePreview/GetStartedPagePreviewComp.vue"
      ),
    QuestionLeadsStripePage: () =>
      import(
        /* webpackChunkName: "QuestionLeadsStripePage" */ "../../NavbarPagePreview/QuestionLeadsStripePage.vue"
      ),
    ResultPreviewPage: () =>
      import(
        /* webpackChunkName: "ResultPreviewPage" */ "../../NavbarPagePreview/ResultPreviewPage.vue"
      ),
  },
  data() {
    return {
      singleStepStatue: 0,
      scrollOffset: 0,
      currPageName: null,
      showName: false,
      addPageLoader: false,
      selectedNewPage: null,
      duplicateSpinner: false,
      deleteSpinner: false,
      dragging: false,
      page: "",
      deviceType: "desktop",
      pages: [],
      addPageValues1: [
        {
          icon: "/images/get.svg",
          name: "Get started page",
          value: "getStartedPage",
          isEdit: false,
        },
        {
          name: "Single answer",
          value: "singleAnswer",
          icon: "/images/singleAnswer.svg",
          isEdit: false,
        },
        {
          name: "Multiple answer",
          value: "multipleAnswer",
          icon: "/images/multipleAnswer.svg",
          isEdit: false,
        },
        {
          name: "Lead page",
          value: "leadPage",
          icon: "/images/lead.svg",
          isEdit: false,
        },
        // {
        //   name: "Payment",
        //   icon: "/images/stripe.svg",
        //   value: "stripePage",
        //   isEdit: false,
        // },
        // {
        //    name: "Slider",
        //   icon: "/images/slider.svg",
        //   value: "sliderPage",
        //   isEdit: false,
        //  }
        // {
        //   name: "Drop down",
        //   value: "dropDown",
        //   icon: "/images/dropDown.svg",
        //   isEdit: false,
        // },
        // {
        //   name: "Free text",
        //   value: "freeText",
        //   icon: "/images/freeText.svg",
        //   isEdit: false,
        // },
      ],
      addPageValues2: [
        {
          name: "Result Page",
          icon: "/images/result.svg",
          value: "resultPage",
          isEdit: false,
        },
        {
          name: "Stripe Payment",
          icon: "/images/stripe.svg",
          value: "stripePage",
          isEdit: false,
        },
      ],
      initiateDeletPage: {
        index: null,
        page: null,
      },

      windowWidth: window.innerWidth,

      zoomValue: this.pageZoomValue,
      isOpen: false,
      leadPageFeatures: [
        {
          id: "fullName",
          title: "Full Name",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 11.8333C4 10.5 6.66667 9.76667 8 9.76667C9.33333 9.76667 12 10.5 12 11.8333V12.5H4M10 6.5C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5C7.46957 8.5 6.96086 8.28929 6.58579 7.91421C6.21071 7.53914 6 7.03043 6 6.5C6 5.96957 6.21071 5.46086 6.58579 5.08579C6.96086 4.71071 7.46957 4.5 8 4.5C8.53043 4.5 9.03914 4.71071 9.41421 5.08579C9.78929 5.46086 10 5.96957 10 6.5ZM2 3.83333V13.1667C2 13.5203 2.14048 13.8594 2.39052 14.1095C2.64057 14.3595 2.97971 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V3.83333C14 3.47971 13.8595 3.14057 13.6095 2.89052C13.3594 2.64048 13.0203 2.5 12.6667 2.5H3.33333C2.59333 2.5 2 3.1 2 3.83333Z" fill="#73738D"/>
</svg>
`,
        },
        {
          id: "phoneNumber",
          title: "Phone Number",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.41333 7.69333C5.37333 9.58 6.92 11.1267 8.80667 12.0867L10.2733 10.62C10.46 10.4333 10.72 10.38 10.9533 10.4533C11.7 10.7 12.5 10.8333 13.3333 10.8333C13.5101 10.8333 13.6797 10.9036 13.8047 11.0286C13.9298 11.1536 14 11.3232 14 11.5V13.8333C14 14.0101 13.9298 14.1797 13.8047 14.3047C13.6797 14.4298 13.5101 14.5 13.3333 14.5C10.3275 14.5 7.44487 13.306 5.31946 11.1805C3.19404 9.05513 2 6.17245 2 3.16667C2 2.98986 2.07024 2.82029 2.19526 2.69526C2.32029 2.57024 2.48986 2.5 2.66667 2.5H5C5.17681 2.5 5.34638 2.57024 5.4714 2.69526C5.59643 2.82029 5.66667 2.98986 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "Email",
          title: "Email",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3334 5.83333L8.00004 9.16666L2.66671 5.83333V4.5L8.00004 7.83333L13.3334 4.5M13.3334 3.16666H2.66671C1.92671 3.16666 1.33337 3.76 1.33337 4.5V12.5C1.33337 12.8536 1.47385 13.1928 1.7239 13.4428C1.97395 13.6929 2.31309 13.8333 2.66671 13.8333H13.3334C13.687 13.8333 14.0261 13.6929 14.2762 13.4428C14.5262 13.1928 14.6667 12.8536 14.6667 12.5V4.5C14.6667 3.76 14.0667 3.16666 13.3334 3.16666Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "Date",
          title: "Date",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 9.16667H11V11.0467L12.6267 11.9867L12.1267 12.8533L10 11.6267V9.16667ZM12.6667 5.83333H3.33333V13.1667H6.44667C6.16 12.56 6 11.88 6 11.1667C6 9.92899 6.49167 8.74201 7.36684 7.86684C8.242 6.99167 9.42899 6.5 10.6667 6.5C11.38 6.5 12.06 6.66 12.6667 6.94667V5.83333ZM3.33333 14.5C2.59333 14.5 2 13.9 2 13.1667V3.83333C2 3.09333 2.59333 2.5 3.33333 2.5H4V1.16667H5.33333V2.5H10.6667V1.16667H12V2.5H12.6667C13.0203 2.5 13.3594 2.64048 13.6095 2.89053C13.8595 3.14057 14 3.47971 14 3.83333V7.9C14.8267 8.74 15.3333 9.89334 15.3333 11.1667C15.3333 12.4043 14.8417 13.5913 13.9665 14.4665C13.0913 15.3417 11.9043 15.8333 10.6667 15.8333C9.39333 15.8333 8.24 15.3267 7.4 14.5H3.33333ZM10.6667 7.93333C9.80913 7.93333 8.98672 8.27399 8.38035 8.88036C7.77399 9.48672 7.43333 10.3091 7.43333 11.1667C7.43333 12.9533 8.88 14.4 10.6667 14.4C11.0913 14.4 11.5117 14.3164 11.904 14.1539C12.2963 13.9914 12.6527 13.7532 12.953 13.453C13.2532 13.1527 13.4914 12.7963 13.6539 12.404C13.8164 12.0117 13.9 11.5913 13.9 11.1667C13.9 9.38 12.4533 7.93333 10.6667 7.93333Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "Website",
          title: "Website",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9067 9.83333C10.96 9.39333 11 8.95333 11 8.5C11 8.04667 10.96 7.60667 10.9067 7.16667H13.16C13.2667 7.59333 13.3334 8.04 13.3334 8.5C13.3334 8.96 13.2667 9.40667 13.16 9.83333M9.72671 13.54C10.1267 12.8 10.4334 12 10.6467 11.1667H12.6134C11.9734 12.2667 10.9534 13.12 9.72671 13.54ZM9.56004 9.83333H6.44004C6.37337 9.39333 6.33337 8.95333 6.33337 8.5C6.33337 8.04667 6.37337 7.6 6.44004 7.16667H9.56004C9.62004 7.6 9.66671 8.04667 9.66671 8.5C9.66671 8.95333 9.62004 9.39333 9.56004 9.83333ZM8.00004 13.8067C7.44671 13.0067 7.00004 12.12 6.72671 11.1667H9.27337C9.00004 12.12 8.55337 13.0067 8.00004 13.8067ZM5.33337 5.83333H3.38671C4.02004 4.72667 5.04671 3.87333 6.26671 3.46C5.86671 4.2 5.56671 5 5.33337 5.83333ZM3.38671 11.1667H5.33337C5.56671 12 5.86671 12.8 6.26671 13.54C5.04671 13.12 4.02004 12.2667 3.38671 11.1667ZM2.84004 9.83333C2.73337 9.40667 2.66671 8.96 2.66671 8.5C2.66671 8.04 2.73337 7.59333 2.84004 7.16667H5.09337C5.04004 7.60667 5.00004 8.04667 5.00004 8.5C5.00004 8.95333 5.04004 9.39333 5.09337 9.83333M8.00004 3.18667C8.55337 3.98667 9.00004 4.88 9.27337 5.83333H6.72671C7.00004 4.88 7.44671 3.98667 8.00004 3.18667ZM12.6134 5.83333H10.6467C10.4334 5 10.1267 4.2 9.72671 3.46C10.9534 3.88 11.9734 4.72667 12.6134 5.83333ZM8.00004 1.83333C4.31337 1.83333 1.33337 4.83333 1.33337 8.5C1.33337 10.2681 2.03575 11.9638 3.286 13.214C3.90505 13.8331 4.63998 14.3242 5.44882 14.6592C6.25765 14.9942 7.12456 15.1667 8.00004 15.1667C9.76815 15.1667 11.4638 14.4643 12.7141 13.214C13.9643 11.9638 14.6667 10.2681 14.6667 8.5C14.6667 7.62452 14.4943 6.75761 14.1592 5.94878C13.8242 5.13994 13.3331 4.40501 12.7141 3.78595C12.095 3.1669 11.3601 2.67583 10.5513 2.3408C9.74243 2.00577 8.87552 1.83333 8.00004 1.83333Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "Address",
          title: "Address",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66671 1.16667L1.33337 4.5V5.83333H14V4.5M10.6667 7.16667V11.8333H12.6667V7.16667M1.33337 15.1667H14V13.1667H1.33337M6.66671 7.16667V11.8333H8.66671V7.16667M2.66671 7.16667V11.8333H4.66671V7.16667H2.66671Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "File Upload",
          title: "File Upload",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33329 1.83334L13.3333 5.83334V13.8333C13.3333 14.187 13.1928 14.5261 12.9428 14.7761C12.6927 15.0262 12.3536 15.1667 12 15.1667H3.99996C3.64634 15.1667 3.3072 15.0262 3.05715 14.7761C2.8071 14.5261 2.66663 14.187 2.66663 13.8333V3.16667C2.66663 2.81305 2.8071 2.47391 3.05715 2.22386C3.3072 1.97381 3.64634 1.83334 3.99996 1.83334H9.33329ZM12 13.8333V6.5H8.66663V3.16667H3.99996V13.8333H12ZM7.99996 8.5L10.6666 11.1667H8.99996V13.1667H6.99996V11.1667H5.33329L7.99996 8.5Z" fill="#73738D"/>
</svg>

`,
        },
        {
          id: "OTP Auth",
          title: "OTP Auth",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 11.8333C4 10.5 6.66667 9.76667 8 9.76667C9.33333 9.76667 12 10.5 12 11.8333V12.5H4M10 6.5C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5C7.46957 8.5 6.96086 8.28929 6.58579 7.91421C6.21071 7.53914 6 7.03043 6 6.5C6 5.96957 6.21071 5.46086 6.58579 5.08579C6.96086 4.71071 7.46957 4.5 8 4.5C8.53043 4.5 9.03914 4.71071 9.41421 5.08579C9.78929 5.46086 10 5.96957 10 6.5ZM2 3.83333V13.1667C2 13.5203 2.14048 13.8594 2.39052 14.1095C2.64057 14.3595 2.97971 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V3.83333C14 3.47971 13.8595 3.14057 13.6095 2.89052C13.3594 2.64048 13.0203 2.5 12.6667 2.5H3.33333C2.59333 2.5 2 3.1 2 3.83333Z" fill="#73738D"/>
</svg>
`,
        },
      ],
      questionPagesTypes: [
        {
          id: "singleAnswer",
          title: "singleAnswer",
          svg: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00004 13.3333C6.58555 13.3333 5.229 12.7714 4.2288 11.7712C3.22861 10.771 2.66671 9.41448 2.66671 7.99999C2.66671 6.58551 3.22861 5.22895 4.2288 4.22876C5.229 3.22856 6.58555 2.66666 8.00004 2.66666C9.41453 2.66666 10.7711 3.22856 11.7713 4.22876C12.7715 5.22895 13.3334 6.58551 13.3334 7.99999C13.3334 9.41448 12.7715 10.771 11.7713 11.7712C10.7711 12.7714 9.41453 13.3333 8.00004 13.3333ZM8.00004 1.33333C7.12456 1.33333 6.25765 1.50577 5.44882 1.8408C4.63998 2.17583 3.90505 2.66689 3.286 3.28595C2.03575 4.53619 1.33337 6.23188 1.33337 7.99999C1.33337 9.7681 2.03575 11.4638 3.286 12.714C3.90505 13.3331 4.63998 13.8242 5.44882 14.1592C6.25765 14.4942 7.12456 14.6667 8.00004 14.6667C9.76815 14.6667 11.4638 13.9643 12.7141 12.714C13.9643 11.4638 14.6667 9.7681 14.6667 7.99999C14.6667 7.12452 14.4943 6.25761 14.1592 5.44877C13.8242 4.63993 13.3331 3.90501 12.7141 3.28595C12.095 2.66689 11.3601 2.17583 10.5513 1.8408C9.74243 1.50577 8.87552 1.33333 8.00004 1.33333ZM8.00004 4.66666C7.11599 4.66666 6.26814 5.01785 5.64302 5.64297C5.0179 6.26809 4.66671 7.11594 4.66671 7.99999C4.66671 8.88405 5.0179 9.7319 5.64302 10.357C6.26814 10.9821 7.11599 11.3333 8.00004 11.3333C8.8841 11.3333 9.73194 10.9821 10.3571 10.357C10.9822 9.7319 11.3334 8.88405 11.3334 7.99999C11.3334 7.11594 10.9822 6.26809 10.3571 5.64297C9.73194 5.01785 8.8841 4.66666 8.00004 4.66666Z" fill="#73738D"/>
</svg>`,
        },
        {
          id: "multipleAnswer",
          title: "Multiple Answer",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6667 2.5H3.33333C2.97971 2.5 2.64057 2.64048 2.39052 2.89052C2.14048 3.14057 2 3.47971 2 3.83333V13.1667C2 13.5203 2.14048 13.8594 2.39052 14.1095C2.64057 14.3595 2.97971 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V3.83333C14 3.47971 13.8595 3.14057 13.6095 2.89052C13.3594 2.64048 13.0203 2.5 12.6667 2.5ZM12.6667 3.83333V13.1667H3.33333V3.83333H12.6667ZM6.66667 11.8333L4 9.16667L4.94 8.22L6.66667 9.94667L11.06 5.55333L12 6.5" fill="#73738D"/>
</svg>
`,
        },
        {
          id: "image",
          title: "Image",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6667 13.1667H3.33333V3.83333H12.6667M12.6667 2.5H3.33333C2.97971 2.5 2.64057 2.64048 2.39052 2.89052C2.14048 3.14057 2 3.47971 2 3.83333V13.1667C2 13.5203 2.14048 13.8594 2.39052 14.1095C2.64057 14.3595 2.97971 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V3.83333C14 3.47971 13.8595 3.14057 13.6095 2.89052C13.3594 2.64048 13.0203 2.5 12.6667 2.5ZM9.30667 8.69333L7.47333 11.0533L6.16667 9.48L4.33333 11.8333H11.6667L9.30667 8.69333Z" fill="#73738D"/>
</svg>
`,
        },
        {
          id: "dropdown",
          title: "Dropdown",
          svg: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.94 6.22L8 9.28L11.06 6.22L12 7.16667L8 11.1667L4 7.16667L4.94 6.22Z" fill="#73738D"/>
</svg>
`,
        },
      ],
      isClosePageOptionModal: false,
    };
  },
  computed: {
    showAddPages() {
      const sliderClientsArr = process.env.VUE_APP_SLIDER_CLIENTS_QUIZID;

      const currQuizID = this.getQuizID;

      if (sliderClientsArr) {
        return sliderClientsArr.includes(currQuizID)
          ? [
              ...this.addPageValues1,
              {
                name: "Slider",
                icon: "/images/slider.svg",
                value: "sliderPage",
                isEdit: false,
              },
            ]
          : this.addPageValues1;
      }

      return this.addPageValues1;
    },

    PaymentPageData() {
      return {
        name: "Payment",
        icon: "/images/stripe.svg",
        value: "stripePage",
        isEdit: false,
      };
    },

    dragOptions() {
      return {
        animation: 200,
      };
    },
    ShopifyEmbedCondition() {
      return this.getUserType == "shopify"
        ? this.windowWidth < 1250
          ? true
          : false
        : false;
    },

    getIntegratedApps() {
      return this.getLoadQuizAppsIntegrated;
    },
    ...mapGetters([
      "getEditorPageView",
      "getSelectedPage",
      "getTotalPages",
      "getIsAnyPageEdited",
      "getSelectedTemplateDesign",
      "getProgressBar",
      "getCurrencies",
      "getEditorSettings",
      "getIsloadQuizCustomizationTriggered",
      "getIsUpdateQuizCustomizationTriggered",
      "getQuizID",
      "GetTokenFromMetaTag",
      "getLoadQuizAppsIntegrated",
      "getNavbarData",
      "getPageZoom",
      "getIsCustomNavOpen",
      "getIsEditQuizSaveLoading",
    ]),
    isEditorQuizSaveLoading() {
      return this.getIsEditQuizSaveLoading;
    },
    isCustomNavOpen() {
      return this.getIsCustomNavOpen;
    },
    pageZoomValue() {
      return this.getPageZoom;
    },

    getUserType() {
      return this.getNavbarData.userType;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
    totalPages() {
      // if(this.getTotalPages.length){
      return this.getTotalPages;
      // }
    },
    getUpdateQuizTriggered() {
      return this.getIsUpdateQuizCustomizationTriggered;
    },
    getResultPage() {
      let page,
        count = 0;
      this.pages.forEach((x) => x.value == "resultPage" && count++);
      if (count > 0) {
        page = true;
      } else {
        page = false;
      }
      return page;
    },
    getStripePage() {
      let page,
        count = 0;
      this.pages.forEach((x) => x.value == "stripePage" && count++);
      if (count > 0) {
        page = true;
      } else {
        page = false;
      }
      return page;
    },
    ResultPageIndex() {
      return this.pages.findIndex((e) => e.value == "resultPage");
    },
    getTotalLeadPagesLength() {
      let count = 0;
      this.getTotalPages.forEach((page) => page.value == "leadPage" && count++);
      //   this.pageDesign.blocksArray.forEach((x) => x.type == "button" && count++);
      return count;
    },
    isPaymentPageExist() {
      let count = 0;
      this.pages.forEach((x) => x.value == "stripePage" && count++);
      return count > 0 ? true : false;
    },
  },
  watch: {
    pages: {
      deep: true,
      handler() {
        const isResultPresent = this.getResultPage;

        const resultPageOrder = this.ResultPageIndex;
        let tempArray = [...this.pages];
        if (isResultPresent && resultPageOrder) {
          tempArray.push(tempArray.splice(resultPageOrder, 1)[0]);
        }
        const newPages = [];
        for (let i = 0; i < tempArray.length; i++) {
          tempArray[i].order = i;
          newPages.push(tempArray[i]);
        }
        this.setTotalPages(newPages);
        this.setIsAnyPageEdited(true);
      },
    },
    totalPages: {
      deep: true,
      handler: function () {
        if (
          !this.getIsloadQuizCustomizationTriggered &&
          !this.getUpdateQuizTriggered
        ) {
          this.setIsAnyPageEdited(true);
        }
      },
    },
    getUpdateQuizTriggered() {
      if (this.getUpdateQuizTriggered) {
        this.pages = this.getTotalPages;
      }
    },
    zoomValue(newVal) {
      this.setPageZoom(parseInt(newVal));
    },
  },

  methods: {
    scrollHorizontally(event) {
      event.preventDefault();
      const container = this.$refs.AllPagesContainerRef;
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        container.scrollLeft += event.deltaY > 0 ? 10 : -10;
        scrollAmount += 10;

        // Stop the interval when we've scrolled enough
        if (scrollAmount >= Math.abs(event.deltaY)) {
          clearInterval(slideTimer);
        }
      }, 10);
    },
    openIntegration() {
      this.$router.push("/integrations/list");
    },
    isDisablePaymentPage(value) {
      return value == "stripePage"
        ? this.isPaymentConnected(value)
          ? this.isPaymentPageExist
            ? true
            : false
          : true
        : false;
    },
    shrinkName(name) {
      return name.length > 50 ? ` ${name.slice(0, 50)}...` : name;
    },
    checkIsGetStarted(value) {
      return value == "getStartedPage" ? true : false;
    },
    checkIsDataPage(value) {
      let pages = [
        "singleAnswer",
        "multipleAnswer",
        "leadPage",
        "stripePage",
        "sliderPage",
      ];
      return pages.includes(value) ? true : false;
    },

    showPageName(name) {
      if (name) {
        this.currPageName = name;
        this.showName = true;
      }
    },
    hidePageName() {
      this.currPageName = null;
      this.showName = false;
    },
    openFullScreenApp() {
      let baseUrl = window.location.origin;
      let paramID = this.$route.params.id;

      let finalURL =
        baseUrl +
        `/authenticate/shopify/editor?key=${paramID}&code=${localStorage.getItem(
          "user_access_token"
        )}`;

      window.open(finalURL, "_blank");
    },

    getDisabledPage(value) {
      if (value == "resultPage") {
        return this.getResultPage;
      }
      return false;
      // else{
      //     return this.getStripePage
      // }
    },
    CloseDeleteModal() {
      $("#DeleteConfirmModal").modal("hide");
    },
    OpenModal(index, page) {
      this.initiateDeletPage.index = index;
      this.initiateDeletPage.page = page;
      $("#DeleteConfirmModal").modal("show");
    },

    openPlansModal() {
      this.$refs.PlansModalRefNav.OpenModal();
    },
    updateQuizNav() {
      this.$emit("UpdateQuiz");
    },
    navigateToQuizDesign() {
      window.location.href = "/dashboard";
    },
    // changeTheme() {
    //   // window.location.href = '/quizDesigns'
    //   $("#settingsModal").modal("hide");
    //   this.$refs.ChooseTemplateNavRef.OpenModal();
    // },
    faClass(icon) {
      return `fa fa-${icon}`;
    },
    ...mapMutations([
      "setEditorPageView",
      "setSelectedPage",
      "setTotalPages",
      "setIsAnyPageEdited",
      "setCurrPageOrder",
      "updateSelectedCurrency",
      "updateShowLogo",
      "setUpgradePlansModal",
      "setPageZoom",
      "toggleCustomNav",
      "setEditorQuizSaveLoading",
    ]),
    toggleView(val) {
      this.setEditorPageView(val);
    },

    createLeadPageDesign(pageDesign) {
      if (this.getTotalLeadPagesLength >= 1) {
        let newPageDesign = { ...pageDesign };
        let newBlocksArr = newPageDesign.blocksArray.filter(
          (block) => block.type == "text" || block.type == "button"
        );
        newPageDesign.blocksArray = newBlocksArr;

        return newPageDesign;
      } else {
        return pageDesign;
      }
    },
    isPaymentConnected(value) {
      if (value == "stripePage")
        if (this.getLoadQuizAppsIntegrated.length > 0) {
          return this.getLoadQuizAppsIntegrated.includes("stripe") ||
            this.getLoadQuizAppsIntegrated.includes("paypal")
            ? true
            : false;
        } else {
          return false;
        }
      return false;
    },

    HandleFreeQuestionLimitModal() {
      let obj = {
        isOpen: true,
        title: "Upgrade to Add New Questions.",
        subText: `You have achieved your questions limit, you should upgrade your plan to add new questions.`,
      };

      this.setUpgradePlansModal(obj);

      return;
    },
    async addPage(data) {
      this.selectedNewPage = data;
      this.addPageLoader = true;

      if (data.value == "singleAnswer" || data.value == "multipleAnswer") {
        const constructObject = (type) => {
          const design = this.getSelectedTemplateDesign[type];

          const defaults = InitialPageValues.PageValues[type + "Design"] || {};

          return {
            id: Date.now(),
            name: design?.name || defaults.name,
            value: design?.value || defaults.value,
            img: design?.img || defaults.img,
            isMasterQuestion:
              design?.isMasterQuestion !== undefined
                ? design.isMasterQuestion
                : defaults.isMasterQuestion !== undefined
                ? defaults.isMasterQuestion
                : false,
            icon: design?.icon || defaults.icon,
            isEdit:
              design?.isEdit !== undefined
                ? design.isEdit
                : defaults.isEdit !== undefined
                ? defaults.isEdit
                : false,
            order: this.pages.length,
            pageDesign: design?.pageDesign || defaults.pageDesign,
          };
        };

        try {
          let obj;
          if (data.value === "singleAnswer") {
            obj = constructObject("singleAnswerQuestion");
          } else if (data.value === "multipleAnswer") {
            obj = constructObject("multipleAnswerQuestion");
          } else {
            return;
          }

          const page = await this.AddQuestionPage(obj);
          if (page === undefined) return;

          this.pages.push(page);
          this.setSelectedPage(JSON.parse(JSON.stringify(page)));
        } catch (error) {
          let { message } = error.response.data;
          if (message && message === "Plan Questions Limit Exceed") {
            this.HandleFreeQuestionLimitModal();
          } else {
            this.$toasted.show("Error occurred, Unable to add page!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
      } else if (data.value == "leadPage") {
        try {
          const obj = {};
          obj.id = Date.now();
          obj.name = this.getSelectedTemplateDesign.leadPage.name;
          obj.value = this.getSelectedTemplateDesign.leadPage.value;
          (obj.img = this.getSelectedTemplateDesign.leadPage.img),
            (obj.icon = this.getSelectedTemplateDesign.leadPage.icon),
            (obj.isEdit = this.getSelectedTemplateDesign.leadPage.isEdit);
          obj.order = this.pages.length;
          obj.pageDesign = this.createLeadPageDesign(
            this.getSelectedTemplateDesign.leadPage.pageDesign
          );

          const page = await this.AddLeadPage(obj);
          if (page === undefined) return;

          this.pages.push(page);
          this.setSelectedPage(JSON.parse(JSON.stringify(page)));
        } catch (error) {
          this.$toasted.show("Error occured, Unable to add page!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } else if (data.value == "sliderPage") {
        const obj = {};
        obj.id = Date.now();
        obj.name = data.name;
        obj.value = data.value;
        (obj.img = "/images/bar.svg"),
          (obj.icon = data.icon),
          (obj.isEdit = data.isEdit);
        obj.order = this.pages.length;
        obj.pageDesign = InitialPageValues.PageValues.sliderPageDesign;
        this.pages.push(obj);
        this.setSelectedPage(JSON.parse(JSON.stringify(obj)));
      } else if (data.value == "resultPage") {
        try {
          const obj = {};
          obj.id = Date.now();
          obj.name = this.getSelectedTemplateDesign.resultPageSetting.name;
          obj.value = this.getSelectedTemplateDesign.resultPageSetting.value;
          (obj.img = this.getSelectedTemplateDesign.resultPageSetting.img),
            (obj.icon = this.getSelectedTemplateDesign.resultPageSetting.icon),
            (obj.isEdit =
              this.getSelectedTemplateDesign.resultPageSetting.isEdit);
          obj.order = this.pages.length;
          obj.pageDesign =
            this.getSelectedTemplateDesign.resultPageSetting.pageDesign;
          // Set Image Fit as default
          const resultPageIndex = this.getProductRankOptionArrayIndex(
            obj.pageDesign
          );
          if (resultPageIndex) {
            obj.pageDesign.blocksArray[resultPageIndex].style.imageFit = "Fit";
          }
          this.pages.push(obj);
          this.setSelectedPage(JSON.parse(JSON.stringify(obj)));
        } catch (error) {
          this.$toasted.show("Error occured, Unable to add page!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } else if (data.value == "getStartedPage") {
        try {
          const obj = {};
          obj.id = Date.now();
          obj.name = this.getSelectedTemplateDesign.startedPage.name;
          obj.value = this.getSelectedTemplateDesign.startedPage.value;
          (obj.img = this.getSelectedTemplateDesign.startedPage.img),
            (obj.icon = this.getSelectedTemplateDesign.startedPage.icon),
            (obj.isEdit = this.getSelectedTemplateDesign.startedPage.isEdit);
          obj.order = this.pages.length;
          obj.status = this.getSelectedTemplateDesign.startedPage.status;
          obj.pageDesign =
            this.getSelectedTemplateDesign.startedPage.pageDesign;
          this.pages.push(obj);

          this.setSelectedPage(JSON.parse(JSON.stringify(obj)));
        } catch (error) {
          this.$toasted.show("Error occured, Unable to add page!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } else if (data.value == "stripePage") {
        try {
          const obj = {};
          obj.id = Date.now();
          obj.name =
            this.getSelectedTemplateDesign?.stripePageDesign?.name ||
            InitialPageValues.PageValues.stripePageDesign.name;
          obj.value =
            this.getSelectedTemplateDesign?.stripePageDesign?.value ||
            InitialPageValues.PageValues.stripePageDesign.value;
          (obj.img =
            this.getSelectedTemplateDesign?.stripePageDesign?.img ||
            InitialPageValues.PageValues.stripePageDesign.img),
            (obj.icon =
              this.getSelectedTemplateDesign?.stripePageDesign?.icon ||
              InitialPageValues.PageValues.stripePageDesign.icon),
            (obj.isEdit =
              this.getSelectedTemplateDesign?.stripePageDesign?.isEdit ||
              InitialPageValues.PageValues.stripePageDesign.isEdit);
          obj.order = this.pages.length;
          obj.pageDesign =
            this.getSelectedTemplateDesign?.stripePageDesign?.pageDesign ||
            InitialPageValues.PageValues.stripePageDesign.pageDesign;
          let priceIndex = this.getPaymentPriceIndex(
            obj.pageDesign.blocksArray
          );
          if (priceIndex > -1) {
            if (this.getLoadQuizAppsIntegrated.includes("paypal")) {
              obj.pageDesign.blocksArray[priceIndex].selectedPaymentMethod =
                "Paypal";
            }
            if (this.getLoadQuizAppsIntegrated.includes("stripe")) {
              obj.pageDesign.blocksArray[priceIndex].selectedPaymentMethod =
                "Stripe";
            }
          }

          this.pages.push(obj);

          this.setSelectedPage(JSON.parse(JSON.stringify(obj)));
        } catch (error) {
          this.$toasted.show("Error occured, Unable to add page!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      this.addPageLoader = false;
      this.closeNewPageModal();
    },
    getPaymentPriceIndex(arr) {
      let index = arr.findIndex((x) => x.type == "price");
      return index;
    },
    closeNewPageModal() {
      $("#AddQuestionModal").modal("hide");
      this.selectedNewPage = null;
    },
    addNewPage() {
      $("#AddQuestionModal").modal("show");
    },

    CloseDeleteQustionModal() {
      $("#DeleteQuestionModal").modal("hide");
    },

    getProductRankOptionArrayIndex(obj) {
      const index = obj.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      }
    },
    async AddQuestionPage(obj) {
      try {
        let data = {
          question: JSON.stringify(obj),
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/createQuestionPage`, data);

        if (response.status == 200) {
          return response.data.data;
        }
        if (response.status == 201 && response.data.status == "fail") {
          let obj = {
            isOpen: true,
            title: "Upgrade to Add New Questions.",
            subText: `You have achieved your questions limit, you should upgrade your plan to add new questions.`,
          };

          this.setUpgradePlansModal(obj);

          return;
        }
      } catch (error) {
        if (error) {
          let { message } = error.response.data;
          if (message && message != "Plan Questions Limit Exceed") {
            this.$toasted.show("Error occured, Unable to add page!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
        throw error;
      }
    },
    async AddLeadPage(obj) {
      try {
        let data = {
          leadPage: JSON.stringify(obj),
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/createLeadPage`, data);

        if (response.status == 200) {
          return response.data.data;
        }
        if (response.status == 201 && response.data.status == "fail") {
          let obj = {
            isOpen: true,
            title: "Upgrade to Add New Lead Page.",
            subText: `You have achieved your pages limit, you should upgrade your plan to add new page.`,
          };

          this.setUpgradePlansModal(obj);

          return;
        }
      } catch (error) {
        if (error) {
          let { message } = error.response.data;
          if (message && message != "Plan Questions Limit Exceed") {
            this.$toasted.show("Error occured, Unable to add page!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
        throw error;
      }
    },

    changePageSelected(page) {
      this.$store.commit("setCurrSidebarView", "pageDesign");
      this.setSelectedPage(JSON.parse(JSON.stringify(page)));
    },
    async removePage(index, page) {
      this.deleteSpinner = true;
      try {
        let data = {
          pageValue: page.value,
          pageId: page.id,
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/removePage`, data);
        if (response.status == 200) {
          const length = this.totalPages.length;

          // If Last element is being deleted
          if (page.order === length - 1) {
            let currOrder = page.order;
            this.pages.splice(index, 1);
            let newSelectedPage = this.pages[currOrder - 1];
            this.changePageSelected(newSelectedPage);
          } else {
            this.pages.splice(index, 1);
            const tempArr = [];
            this.pages.forEach((element) => {
              if (element.order > page.order) {
                element.order--;
              }
              tempArr.push(element);
            });
            this.pages = tempArr;
            this.changePageSelected(this.pages[page.order]);
          }
          this.$toasted.show("Page removed ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.initiateDeletPage.index = null;
          this.initiateDeletPage.page = null;
          this.CloseDeleteModal();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.deleteSpinner = false;
      }
    },
    prevPage() {
      const length = this.getTotalPages.length;
      const currentPageOrder = this.getSelectedPage.order;
      let current = currentPageOrder;

      if (currentPageOrder > 0) {
        current = currentPageOrder == length ? current - 2 : current - 1;
        // current -= 1;
        this.setSelectedPage(
          JSON.parse(JSON.stringify(this.getTotalPages[current]))
        );
      } else {
        current = length - 1;
        this.setSelectedPage(
          JSON.parse(JSON.stringify(this.getTotalPages[current]))
        );
      }

      this.scrollToSelectedProduct();
    },
    nextPage() {
      const length = this.getTotalPages.length;
      const currentPageOrder = this.getSelectedPage.order;
      let current = currentPageOrder;
      if (length - currentPageOrder > 1) {
        current += 1;
        this.setSelectedPage(
          JSON.parse(JSON.stringify(this.getTotalPages[current]))
        );
      } else {
        current = 0;
        this.setSelectedPage(
          JSON.parse(JSON.stringify(this.getTotalPages[current]))
        );
      }

      this.scrollToSelectedProduct();
    },

    getPageOrder(TotalPages, currPageId) {
      let index = TotalPages.findIndex((x) => x.id == currPageId);
      return index;
    },
    async saveData() {
      const TotalPages = this.totalPages;
      const progressBar = this.getProgressBar;
      const setting = this.getEditorSettings;
      if (Object.keys(this.$store.getters.getFontFamilyTracker).length) {
        setting["selected_fonts"] = this.$store.getters.getFontFamilyTracker;
      }
      this.setEditorQuizSaveLoading(true);
      try {
        let data = {
          totalPages: JSON.stringify(TotalPages),
          progressBar: progressBar,
          setting: setting,
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/saveQuizCustomization`, data);
        if (response.status == 200) {
          this.$toasted.show("Changes saved ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          let currPageOrder = await this.getPageOrder(
            TotalPages,
            this.getSelectedPage.id
          );
          await this.setCurrPageOrder(currPageOrder);
          await this.setIsAnyPageEdited(false);
          await this.$emit("GetAllPages");
          return true;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.setEditorQuizSaveLoading(false);
      }
    },
    // OpenModalEmit() {
    //     this.$emit("openModal");
    // },

    changeIsEdit(index) {
      this.pages.forEach((obj) => {
        obj.isEdit = false;
      });
      this.pages[index].isEdit = true;
    },

    singleStepStatus() {
      this.getEditorSettings.single_step_status = this.singleStepStatue ? 1 : 0;
      // if (this.getEditorSettings.isFreePlan) {
      //   this.openUpgradePlansModalForWaterMark();
      //   return;
      // }
      // this.updateShowLogo(this.showLogo);
    },

    async duplicatePage(page, index) {
      try {
        this.duplicateSpinner = true;

        if (page.value == "singleAnswer" || page.value == "multipleAnswer") {
          const questionType =
            page.value === "singleAnswer"
              ? this.getSelectedTemplateDesign.singleAnswerQuestion
              : page.value === "multipleAnswer"
              ? this.getSelectedTemplateDesign.multipleAnswerQuestion
              : null;

          if (questionType) {
            const obj = {
              id: Date.now(),
              name: `copy - ${page.name}`,
              value: questionType.value,
              img: questionType.img,
              isMasterQuestion: questionType.isMasterQuestion,
              icon: questionType.icon,
              isEdit: questionType.isEdit,
              order: this.pages.length,
              pageDesign: page.pageDesign,
            };

            // Add the new question page and handle the response
            const duplicate = await this.AddQuestionPage(obj);
            if (duplicate === undefined) return;

            // Insert the duplicate in the correct position
            if (index >= 0 && index <= this.pages.length) {
              this.pages.splice(index + 1, 0, duplicate);
            } else {
              this.pages.push(duplicate);
            }

            // Set the selected page and display a toast message
            this.setSelectedPage(JSON.parse(JSON.stringify(duplicate)));
            this.$toasted.show("Page duplicated!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } else if (page.value == "leadPage") {
          try {
            const obj = {};
            obj.id = Date.now();
            obj.name = `copy - ${page.name}`;
            obj.value = this.getSelectedTemplateDesign.leadPage.value;
            (obj.img = this.getSelectedTemplateDesign.leadPage.img),
              (obj.icon = this.getSelectedTemplateDesign.leadPage.icon),
              (obj.isEdit = this.getSelectedTemplateDesign.leadPage.isEdit);
            obj.order = this.pages.length;
            obj.pageDesign = await this.createLeadPageDesign(page.pageDesign);

            const duplicate = await this.AddLeadPage(obj);
            if (duplicate === undefined) return;

            if (index >= 0 && index <= this.pages.length) {
              this.pages.splice(index + 1, 0, duplicate);
            } else {
              this.pages.push(duplicate);
            }
            this.setSelectedPage(JSON.parse(JSON.stringify(duplicate)));
            this.$toasted.show("Page duplicated!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          } catch (error) {
            this.$toasted.show("Error occured, Unable to add page!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } else if (page.value == "getStartedPage") {
          try {
            const obj = {};
            obj.id = Date.now();
            obj.name = `copy - ${page.name}`;
            obj.value = this.getSelectedTemplateDesign.startedPage.value;
            (obj.img = this.getSelectedTemplateDesign.startedPage.img),
              (obj.icon = this.getSelectedTemplateDesign.startedPage.icon),
              (obj.isEdit = this.getSelectedTemplateDesign.startedPage.isEdit);
            obj.order = this.pages.length;
            obj.pageDesign = page.pageDesign;

            if (index >= 0 && index <= this.pages.length) {
              this.pages.splice(index + 1, 0, obj);
            } else {
              this.pages.push(obj);
            }
            this.setSelectedPage(JSON.parse(JSON.stringify(obj)));
            this.$toasted.show("Page duplicated!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });

            this.isClosePageOptionModal = true;
          } catch (error) {
            this.$toasted.show("Error occured, Unable to add page!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
      } catch (error) {
        let { message } = error.response.data;
        if (message && message == "Plan Questions Limit Exceed") {
          this.HandleFreeQuestionLimitModal();
        } else {
          this.$toasted.show("Error occured, Unable to add page!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.duplicateSpinner = false;
      }
    },
    navigateToPreview() {
      // this.$router.push("/test/" + this.getEditorSettings.quizKey);
      this.$emit("togglePreview");
    },

    initateDuplicatePage(data) {
      let block = this.pages.find((block) => block.id == data.id);
      this.isClosePageOptionModal = false;
      this.duplicatePage(block, data.index);
    },

    scrollToSelectedProduct() {
      const container = this.$refs.AllPagesContainerRef;

      const selectedQuiz = container.querySelector(".selectedPage");
      if (!selectedQuiz) return;

      selectedQuiz.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    },

    async DeletePage({ page, index }) {
      this.deleteSpinner = true;
      try {
        let data = {
          pageValue: page.value,
          pageId: page.id,
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/removePage`, data);
        if (response.status == 200) {
          const length = this.totalPages.length;

          // If Last element is being deleted
          if (page.order === length - 1) {
            let currOrder = page.order;
            this.pages.splice(index, 1);
            let newSelectedPage = this.pages[currOrder - 1];
            this.changePageSelected(newSelectedPage);
          } else {
            this.pages.splice(index, 1);
            const tempArr = [];
            this.pages.forEach((element) => {
              if (element.order > page.order) {
                element.order--;
              }
              tempArr.push(element);
            });
            this.pages = tempArr;
            this.changePageSelected(this.pages[page.order]);
          }
          this.$toasted.show("Page removed ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.initiateDeletPage.index = null;
          this.initiateDeletPage.page = null;
          // this.CloseDeleteModal();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.deleteSpinner = false;
      }
    },
  },
  created() {
    this.pages = this.getTotalPages;
  },
  mounted() {
    this.singleStepStatue =
      this.getEditorSettings.single_step_status == 1 ? true : false;
  },
  // beforeDestroy() {
  //   window.removeEventListener("click", this.closeDropdown);
  // },
};
</script>

<style scoped>
.CustomNavContainer {
  position: relative;
}

.ArrowContainer {
  width: 64px;
  height: 15px;
  z-index: 100 !important;
  /* background: #fff; */
  background: #b7a3cb;
  /* background: #F7EEFF; */
  color: #4d1b7e;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarCustom {
  background: transparent;
  color: #4d1b7e;
  display: block;
}

.navbarCustom__middle {
  flex: 1;
}

.navbarCustom__icon-container {
  height: 30px;
  width: 30px;
}
.navbarCustom__icon-container.large {
  cursor: pointer;
  background: transparent;
  height: 40px;
  width: 40px;
}
.navbarCustom__icon-container.active {
  background: rgb(179 175 182 / 30%);
}
.navbarCustom__form {
  flex: 0.8;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: #ffffff;
}
.navbarCustom input {
  border: none;
  color: inherit;
  outline: none;
  font-size: 15px;
}
.navbarCustom input::placeholder {
  color: #ffffff;
}

.disabledButton {
  background: #b3afb6 !important;
  pointer-events: none;
}
.NavDropdownMenu {
  max-height: 70vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.navbarCustom__title {
  font-size: 14px;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.pag-btn .btn {
  padding: 7px 5px;
  background: transparent;
}
.pag-btn .btn:hover {
  background: transparent !important;
}
.pag-btn .btn::after {
  display: none;
}
.pag-btn .dropdown-item {
  padding: 5px 10px;
}
.pag-btn .dropdownMenuButton {
  margin: 0px;
}
.dropdown-toggle::after {
  content: none;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent;
  color: #16181b;
  outline: none;
}
.drop-page-item:hover {
  background-color: #f4f3f5;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #f4f3f5 !important;
}

.btn.modal-btn {
  text-align: left;
}
.modal-btn {
  color: #ffffff;
  border-radius: 4px !important;
  padding: 7px 0px 0px 0px;
}
.curry-opt {
  padding: 0px;
}
.curry-opt-select {
  border: 1px solid rgb(255 255 255 / 35%);
  border-radius: 4px;
  padding: 0px 6px 0px 4px;
}
.modal-btn span {
  box-shadow: 0px 6px 8px rgb(0 0 0 / 12%) !important;
  padding: 2px 5px;
  background: rgb(255 255 255 / 14%);
  border-radius: 3px;
}

.modal-btn:hover {
  color: #ffffff;
}
.jump-logic hr {
  border-top: 1px solid rgb(254 252 252 / 33%);
  margin-bottom: 0px;
}
.result-curry {
  background: none !important;
  color: #ffffff;
}
.jump-logic .dropdown-item {
  padding: 0px;
  justify-content: space-between;
}

/* Show Logo switch Css */
.custom-switch .custom-control-input ~ .custom-control-label::after {
  left: calc(-2.25rem + 1px) !important;
  background: #6d6b6d !important;
}
.custom-switch
  .custom-control-input:checked
  ~ .custom-control-label[data-v-2c6c3e7d]::after,
.custom-switch .custom-control-input ~ .custom-control-label::after {
  height: 16px !important;
  width: 16px !important;
  top: 4px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

.SaveNavbar {
  outline: none;
  background: #4d1b7e;
  color: #ffffff;
  font-family: inherit;
  font-size: 15px;
  font-weight: 500 !important;
  border-radius: 6px;
  width: 90px;
  height: 43px;
}

/* Currency Dropdown styles */
.CurrencyDropdownDiv >>> a {
  padding: 0 !important;
}

.CurrencyDropdownDiv >>> a.active,
.CurrencyDropdownDiv >>> a:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent !important;
}

.DeleteQuizModalContent {
  border-radius: 20px;
  text-align: left;
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}
.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}

/* New Layout Css */
.question-type {
  transition: 0.5s ease all;
}
.question-type:hover,
.selected {
  background: rgb(236 236 236);
}
.pointer-events-none {
  pointer-events: none;
}

.zoomSliderWrapper {
  /* width: 12.5rem; */
  max-width: 350px;
  width: 350px;
  min-width: 100px;
}
input[type="range"].zoomSlider {
  font-size: 1.5rem;
  /* width: 12.5em; */
  width: 100%;
  /* min-width: 0; */
}

/* .zoomSliderContainer .pageNumber {
  color: #0d1216;
  font-weight: 600;
  font-size: 1.1rem;
} */

.zoomSliderContainer .zoomValue {
  color: #0d1216;
  font-weight: 500;
  font-size: 1rem;
}

.space-vertical {
  box-sizing: border-box;
  height: 25px;
  border-right: 1px solid rgba(57, 76, 96, 0.15);
}

.AllQuiz .quizContainer {
  width: 150px;
  height: 100px;
  max-width: 150px;
  max-height: 100px;
  border-radius: 12px;
  background: rgba(114, 122, 129, 0.07);
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* border: 3px solid #f; */
}

.AllQuiz .quizContainer.AddPageContainer {
  background: rgba(3, 151, 204, 0.1);
}

.AllQuiz .quizContainer .pageMenuContainer {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
}

.AllQuiz .quizContainer:hover > .pageMenuContainer {
  display: block;
}

.selectedPage {
  border: 3px solid #4d1b7e;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* quizNameFade */
.quizNameFade-enter-active,
.quizNameFade-leave-active {
  transition: opacity 0.75s ease;
}

.quizNameFade-enter-from,
.quizNameFade-leave-to {
  opacity: 0;
}

.QuizNameContainer {
  background: #1f1f28;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  width: fit-content;
  border-radius: 12px;
}

.settingsModalStyles {
  background: rgba(0, 0, 0, 70%) !important;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12) !important;
  backdrop-filter: blur(14px) !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  padding: 10px 12px;
}

@media only screen and (max-width: 1400px) {
  .zoomSliderWrapper {
    width: 250px;
  }
}

.noteText {
  color: #7c8290;
  font-family: "Poppins";
  font-size: 12px;
}
.integrationAnchor {
  color: #ffa201;
  font-family: "Poppins";
  font-size: 12px;
}
.integrationAnchor:hover {
  text-decoration: underline;
}

/* New PAges Design  */

.pageContainerMain {
  border-radius: 12px;
  padding: 12px;
  background: #f6f6f7;
}
.pageContainerMain .pageTitle {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #9ea0a8;
}
.pageContainerMain .title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #73738d;
}
</style>
